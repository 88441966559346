let pk4Config = window.pk4Config || {};
module.exports = {
  API: pk4Config && pk4Config.API ? pk4Config.API : "https://apis.xpsads.com",
  BaseUrl: "https://frontend.xpsads.com/",
  AdUrl: "https://ads.xpsads.com/",
  userRoleTypes: {
    ROLE_ADMIN: {
      url: "/",
    },
    ROLE_ACCOUNT_MANAGER: {
      url: "/",
    },
    ROLE_PUBLISHER_MANAGER: {
      url: "/",
    },
    ROLE_ACCOUNT_OBSERVER: {
      url: "/",
    },
    ROLE_ACCOUNT_MANAGER_LEAD: {
      url: "/",
    },
    ROLE_PARTNER_DEVELOPMENT: {
      url: "/",
    },
    ROLE_PARTNER_DEVELOPMENT_LEAD: {
      url: "/",
    },
    ROLE_TRAFFICKING_LEAD: {
      url: "/",
    },
    ROLE_PUBLISHER_OBSERVER: {
      url: "/",
    },
  },
  token: {
    expire: 3600,
  },
};
