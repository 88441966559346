import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openAddPrivateBuyModal } from "../../../redux/actions/private-buy-actions";
import { openAddCreativeModal } from "../../../redux/actions/creative-actions";
import { displaySiteBuyId, testDisplayAdUrl } from "../../../constants/defaultValues";
import { openExportReportModal } from "../../../redux/actions/export-report-actions";
import {
  openUpdateDeviceModal,
  openUpdateGeoNameModal,
  openUpdateExelateSegmentsModal,
  openUpdateDmaRegionModal,
  openNewLineModal,
  openUpdateDeliveryModal,
  openUpdateSiteListModal,
  openUpdateCreativeSizeModal,
  openUpdateClickUrlModal,
} from "../../../redux/actions/line-actions";
import {
  Divider,
  Row,
  Col,
  Button,
  Card,
  Typography,
  Switch,
  List,
  Collapse,
  Space,
} from "antd";
import AddLineModal from "../../modals/line/add-line";
import EditDelivery from "../../modals/line/edit-delivery";
import { compose } from "redux";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";
import UsaDmaMap from "../../../pages/map/usaDmaMap";
import { getLocalDate } from "../../../utils/appUtils";

const { Panel } = Collapse;
const { Text, Title } = Typography;

const gridStyle = {
  width: "100%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};

const DisplayLineDetails = ({
  editDisplayLine,
  displayBuys,
  displaySiteBuyList,
}) => {
  const dispatch = useDispatch();
  const [seriesData, setSeriesData] = useState([]);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";
  const [testPageUrl, setTestPageUrl] = useState("");
  const [creativesList, setCreativesList] = useState([]);
  const [testAdUrl, setTestAdUrl] = useState("");

  useEffect(() => {
    const data = [];
    const series = [];
    var url = testDisplayAdUrl;
    let creatives = [];
    if (displaySiteBuyList && displaySiteBuyList.length > 0) {
      var siteBuyId = displaySiteBuyId;
      for (var i = 0; i < displaySiteBuyList.length; i++) {
        var siteBuy = displaySiteBuyList[i];
        if (siteBuy && siteBuy.displaySiteBuyId) {
          if (siteBuy.publisher && siteBuy.publisher.blacklistedAccountIds && siteBuy.publisher.blacklistedAccountIds.length > 0) {
            if (!siteBuy.publisher.blacklistedAccountIds.includes(editDisplayLine && editDisplayLine.accountId)) {
              siteBuyId = siteBuy.displaySiteBuyId;
              break;
            }
          } else {
            siteBuyId = siteBuy.displaySiteBuyId;
            break;
          }
        }
      }
      url = url.concat("?siteBuyId=" + siteBuyId);
      setTestAdUrl(url);
    } else {
      url = url + "?siteBuyId=1";
    }

    if (editDisplayLine && editDisplayLine.imageCreatives) {
      editDisplayLine.imageCreatives.map((creative, index) => {
        var obj = {};
        obj["x"] = creative.imageCreativeVersion.name;
        var arrYaxis = [];
        arrYaxis.push(creative.imageCreativeVersion.startAt);
        arrYaxis.push(creative.imageCreativeVersion.endAt);
        obj["y"] = arrYaxis;
        obj["fillColor"] = "#008FFB"; //generateColorCode("#FF45", 2);
        data.push(obj);

        url = url.concat(
          "&placement=" +
          creative.width +
          "x" +
          creative.height +
          ";" +
          creative.displayLineId +
          ";" +
          creative.imageCreativeId +
          ";" +
          creative.imageCreativeVersion.name
        );

        creatives.push({
          id: creative.imageCreativeId,
          name:
            creative &&
            creative.imageCreativeVersion &&
            creative.imageCreativeVersion.name,
          type: "Image Creatives",
        });
      });
    }
    if (editDisplayLine && editDisplayLine.tagDisplayCreatives) {
      editDisplayLine.tagDisplayCreatives.map((creative, index) => {
        var obj = {};
        obj["x"] = creative.tagDisplayCreativeVersion.name;
        var arrYaxis = [];
        arrYaxis.push(creative.tagDisplayCreativeVersion.startAt);
        arrYaxis.push(creative.tagDisplayCreativeVersion.endAt);
        obj["y"] = arrYaxis;
        obj["fillColor"] = "#008FFB"; //generateColorCode("#008F", 2);
        data.push(obj);

        url = url.concat(
          "&placement=" +
          creative.width +
          "x" +
          creative.height +
          ";" +
          creative.displayLineId +
          ";" +
          creative.tagDisplayCreativeId +
          ";" +
          creative.tagDisplayCreativeVersion.name
        );

        creatives.push({
          id: creative.tagDisplayCreativeId,
          name:
            creative &&
            creative.tagDisplayCreativeVersion &&
            creative.tagDisplayCreativeVersion.name,
          type: "Tag Display Creatives",
        });
      });
    }
    series.push({ data: data });
    setSeriesData(series);
    setTestPageUrl(url);

    setCreativesList(creatives);


    return () => {
      console.log("LineDetails Unmounting");
    };
  }, [displayBuys, editDisplayLine, displaySiteBuyList]);

  const options = {
    chart: {
      height: 350,
      type: "rangeBar",
      barHeight: "50%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        var label = opts.w.globals.labels[opts.dataPointIndex];
        return label;
      },
      style: {
        colors: ["#f3f4f5", "#fff"],
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      show: false,
    },
    grid: {
      row: {
        colors: ["#f3f4f5", "#fff"],
        opacity: 1,
      },
    },
  };

  function _bindCreativeSizes(creativeSizes) {
    return (
      creativeSizes &&
      creativeSizes.length > 0 &&
      creativeSizes.map((size, index) => {
        return (
          <li key={index} className="check-box">
            <span>{`${size.width}x${size.height}`}</span>
          </li>
        );
      })
    );
  }

  function _bindImageCreative(imageCreatives) {
    return imageCreatives.map((imageCreative, index) => {
      return (
        <li key={"imageCreative" + index} className="check-box">
          <Link
            to={`/creatives/image-creative/${imageCreative.imageCreativeId}`}
          >
            <span>
              {imageCreative &&
                imageCreative.imageCreativeVersion &&
                imageCreative.imageCreativeVersion.name}{" "}
            </span>
            <span>
              {imageCreative &&
                imageCreative.imageCreativeVersion &&
                moment
                  .unix(
                    getLocalDate(imageCreative.imageCreativeVersion.startAt) /
                    1000
                  )
                  .format("DD MMM YYYY") +
                " - " +
                moment
                  .unix(
                    getLocalDate(imageCreative.imageCreativeVersion.endAt) /
                    1000
                  )
                  .format("DD MMM YYYY")}
              {imageCreative.imageCreativeVersion.paused != undefined && imageCreative.imageCreativeVersion.paused == true ? " - PAUSED" : ""}
            </span>
            <span>
              {imageCreative &&
                imageCreative.imageCreativeVersion ?
                (imageCreative.width + "*" + imageCreative.height) : ""}
            </span>
          </Link>
          {currentUserPermissionMap[currentUserRole]["canTestAd"] ? (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`${testAdUrl}&placement=${imageCreative.width}x${imageCreative.height};${imageCreative.displayLineId};${imageCreative.imageCreativeId};${imageCreative.imageCreativeVersion.name};`}
            >
              <Button type="link">TEST AD</Button>
            </a>
          ) : (
            ""
          )}
        </li>
      );
    });
  }

  function _bindTagDisplayCreative(tagDisplayCreatives) {
    return tagDisplayCreatives.map((tagDisplayCreative, index) => {
      return (
        <li key={"tagDisplayCreative" + index} className="check-box">
          <Link
            to={`/creatives/tag-display-creative/${tagDisplayCreative.tagDisplayCreativeId}`}
          >
            <span>
              {tagDisplayCreative &&
                tagDisplayCreative.tagDisplayCreativeVersion &&
                tagDisplayCreative.tagDisplayCreativeVersion.name}
            </span>
            <span>
              {tagDisplayCreative &&
                tagDisplayCreative.tagDisplayCreativeVersion &&
                moment
                  .unix(
                    getLocalDate(
                      tagDisplayCreative.tagDisplayCreativeVersion.startAt
                    ) / 1000
                  )
                  .format("DD MMM YYYY") +
                " - " +
                moment
                  .unix(
                    getLocalDate(
                      tagDisplayCreative.tagDisplayCreativeVersion.endAt
                    ) / 1000
                  )
                  .format("DD MMM YYYY")}
              {tagDisplayCreative.tagDisplayCreativeVersion.paused != undefined && tagDisplayCreative.tagDisplayCreativeVersion.paused == true ? " - PAUSED" : ""}
            </span>
            <span>
              {tagDisplayCreative &&
                tagDisplayCreative.tagDisplayCreativeVersion ?
                (tagDisplayCreative.width + "*" + tagDisplayCreative.height) : ""}
            </span>
          </Link>
          {currentUserPermissionMap[currentUserRole]["canTestAd"] ? (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`${testAdUrl}&placement=${tagDisplayCreative.width}x${tagDisplayCreative.height};${tagDisplayCreative.displayLineId};${tagDisplayCreative.tagDisplayCreativeId};${tagDisplayCreative.tagDisplayCreativeVersion.name};`}
            >
              <Button type="link">TEST AD</Button>
            </a>
          ) : (
            ""
          )}
        </li>
      );
    });
  }

  return (
    <>
      {editDisplayLine && editDisplayLine.displayLineVersion ? (
        <>
          <Row>
            {currentUserPermissionMap[currentUserRole][
              "canAccessExportReportTestPage"
            ] ? (
              <Space size={8} style={{ paddingBottom: "10px" }}>
                <Button
                  className="btn-style"
                  type="primary"
                  onClick={() => dispatch(openExportReportModal())}
                >
                  Export Report
                </Button>
                {currentUserPermissionMap[currentUserRole]["canTestAd"]
                  ? creativesList &&
                  creativesList.length > 0 && (
                    <a rel="noopener noreferrer" target="_blank" href={testPageUrl}>
                      <Button type="btn-style">Test Page</Button>
                    </a>
                  )
                  : ""}
              </Space>
            ) : (
              ""
            )}
          </Row>
          <Row gutter={16}>
            {currentUserPermissionMap[currentUserRole][
              "canUpdateLineBilling"
            ] ? (
              <Col span={24}>
                <Card title="Billing">
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {"$" +
                        editDisplayLine.displayLineVersion.rate +
                        " " +
                        editDisplayLine.displayLineVersion.rateType}
                    </Title>
                    <Text type="secondary">Rate</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editDisplayLine.displayLineVersion.billingServer
                        ? editDisplayLine.displayLineVersion.billingServer
                        : "NA"}
                    </Title>
                    <Text type="secondary">Billing Server</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editDisplayLine.displayLineVersion.billingProfile
                        ? editDisplayLine.displayLineVersion.billingProfile
                        : "NA"}
                    </Title>
                    <Text type="secondary">Billing Profile</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {moment
                        .unix(
                          getLocalDate(
                            editDisplayLine.displayLineVersion.startAt
                          ) / 1000
                        )
                        .format("DD MMM YYYY, HH:mm") +
                        " - " +
                        moment
                          .unix(
                            getLocalDate(
                              editDisplayLine.displayLineVersion.endAt
                            ) / 1000
                          )
                          .format("DD MMM YYYY, HH:mm")}
                    </Title>
                    <Text type="secondary">Flight</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Collapse bordered={false}>
                      {editDisplayLine.displayLineVersion.allocations &&
                        editDisplayLine.displayLineVersion.allocations != null ? (
                        <Panel
                          header={
                            JSON.parse(
                              editDisplayLine.displayLineVersion.allocations
                            ).length +
                            " Allocations $" +
                            editDisplayLine.displayLineVersion.totalBudget
                          }
                          key="1"
                        >
                          {JSON.parse(
                            editDisplayLine.displayLineVersion.allocations
                          ).map((allocation) => (
                            <Row gutter={16} className="margin_gap">
                              {Object.keys(allocation).map((key) => (
                                <>
                                  <Col span={6}>
                                    <Title level={4}>
                                      {key
                                        ? moment(key).format("MMMM, YYYY") +
                                        " Budget"
                                        : ""}
                                    </Title>
                                    <span className="icon-dollar">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                    <Text type="secondary">
                                      {allocation[key]["billing"]}
                                    </Text>
                                  </Col>
                                  <Col span={8}>
                                    <Title level={4}>
                                      {"Additional Quantity"}
                                    </Title>
                                    <Text type="secondary">
                                      {allocation[key]["quantity"]}
                                    </Text>
                                  </Col>
                                  <Col span={8}>
                                    <Title level={4}>{"Discrepancy(%)"}</Title>
                                    <Text type="secondary">
                                      {allocation[key]["discrepancy"]}
                                    </Text>
                                  </Col>
                                </>
                              ))}
                            </Row>
                          ))}
                        </Panel>
                      ) : (
                        ""
                      )}
                    </Collapse>
                  </Card.Grid>
                  <Divider />
                  <div style={{ margin: "20px" }}>
                    <Button
                      onClick={() => {
                        dispatch(openNewLineModal("Display"));
                      }}
                      type="link"
                    >
                      Edit
                    </Button>
                  </div>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <Row gutter={16}>
            {currentUserPermissionMap[currentUserRole][
              "canUpdateLineDelivery"
            ] ? (
              <Col span={24}>
                <Card title="Delivery">
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editDisplayLine.campaign.padding
                        ? editDisplayLine.campaign.padding
                        : 10}
                      %
                    </Title>
                    <Text type="secondary">Padding</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editDisplayLine.displayLineVersion.clickWeight
                        ? editDisplayLine.displayLineVersion.clickWeight
                        : 0}
                    </Title>
                    <Text type="secondary">Click Weight</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editDisplayLine.displayLineVersion.deliveryCapType ===
                        "PERCENT"
                        ? editDisplayLine.displayLineVersion
                          .percentDailyDeliveryCap *
                        100 +
                        "%"
                        : editDisplayLine.displayLineVersion.deliveryCapType ===
                          "DISABLED"
                          ? "Disabled"
                          : editDisplayLine.displayLineVersion
                            .fixedDailyDeliveryCap}
                    </Title>
                    <Text type="secondary">Daily Cap</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editDisplayLine.displayLineVersion.frequencyCapLimit &&
                        editDisplayLine.displayLineVersion.frequencyCapLimit > 0
                        ? editDisplayLine.displayLineVersion.frequencyCapLimit +
                        " every " +
                        editDisplayLine.displayLineVersion
                          .frequencyCapDuration +
                        (editDisplayLine.displayLineVersion
                          .frequencyCapDuration > 1
                          ? " days"
                          : " day")
                        : "None"}
                    </Title>
                    <Text type="secondary">Frequency Cap</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Switch
                      disabled={true}
                      defaultChecked={editDisplayLine.displayLineVersion.paused}
                      checked={editDisplayLine.displayLineVersion.paused}
                    ></Switch>
                    {" Paused"}
                  </Card.Grid>
                  <Divider />
                  <div style={{ margin: "20px" }}>
                    <Button
                      onClick={() => {
                        dispatch(openUpdateDeliveryModal("Display"));
                      }}
                      type="link"
                    >
                      Edit
                    </Button>
                  </div>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </>
      ) : (
        ""
      )}

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box toolbar_remove">
            <div className="title">
              <h2>{"Creatives"}</h2>
            </div>

            {editDisplayLine && seriesData && seriesData.length > 0 ? (
              <div id="chart">
                <ReactApexChart
                  options={options}
                  series={seriesData}
                  type="rangeBar"
                  height={50 * (editDisplayLine.imageCreatives.length + editDisplayLine.tagDisplayCreatives.length) + "px"}
                  barheight={"50%"}
                />
              </div>
            ) : (
              ""
            )}
            <div className="card-cnt-sec">
              <div className="card-body">
                <Collapse bordered={false}>
                  <Panel
                    header={
                      editDisplayLine &&
                        editDisplayLine.displayLineVersion &&
                        editDisplayLine.displayLineVersion.creativeSizes &&
                        editDisplayLine.displayLineVersion.creativeSizes !== null
                        ? JSON.parse(
                          editDisplayLine.displayLineVersion.creativeSizes
                        ).length > 1
                          ? `${JSON.parse(
                            editDisplayLine.displayLineVersion.creativeSizes
                          ).length
                          } Sizes`
                          : // : "1 Size"
                          JSON.parse(
                            editDisplayLine.displayLineVersion.creativeSizes
                          ).length === 1
                            ? "1 Size"
                            : "0 Size"
                        : "0 Size"
                    }
                    key="1"
                  >
                    {/* {JSON.stringify(editDisplayLine)} */}
                    <div className="card-body">
                      <ul id="creativeSize">
                        {editDisplayLine &&
                          editDisplayLine.displayLineVersion &&
                          editDisplayLine.displayLineVersion.creativeSizes &&
                          editDisplayLine.displayLineVersion.creativeSizes !==
                          null
                          ? _bindCreativeSizes(
                            JSON.parse(
                              editDisplayLine.displayLineVersion.creativeSizes
                            )
                          )
                          : ""}
                      </ul>
                    </div>
                    <div>
                      {currentUserPermissionMap[currentUserRole][
                        "canUpdateCreativeSizes"
                      ] ? (
                        <Button
                          onClick={() =>
                            dispatch(openUpdateCreativeSizeModal())
                          }
                          type="link"
                        >
                          {"Edit"}
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </Panel>
                </Collapse>
                <Collapse bordered={false}>
                  <Panel header="Details" key="2">
                    <div className="card-body">
                      <ul id="imageCreative">
                        {editDisplayLine && editDisplayLine.imageCreatives
                          ? _bindImageCreative(editDisplayLine.imageCreatives)
                          : ""}
                      </ul>
                      <ul id="tagDisplayCreative">
                        {editDisplayLine && editDisplayLine.tagDisplayCreatives
                          ? _bindTagDisplayCreative(
                            editDisplayLine.tagDisplayCreatives
                          )
                          : ""}
                      </ul>
                    </div>
                  </Panel>
                </Collapse>
              </div>
              {currentUserPermissionMap[currentUserRole][
                "canCreateLineCreative"
              ] ? (
                <div className="card-footer">
                  <Button
                    onClick={() => dispatch(openAddCreativeModal())}
                    type="link"
                  >
                    New Creative
                  </Button>
                  <Button
                    type="link"
                    onClick={() =>
                      dispatch(openUpdateClickUrlModal(creativesList))
                    }
                  >
                    Set Click Url
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Devices"}
            footer={
              currentUserPermissionMap[currentUserRole][
                "canUpdateLineDevices"
              ] ? (
                <div>
                  <Button
                    onClick={() =>
                      dispatch(
                        openUpdateDeviceModal(
                          editDisplayLine &&
                            editDisplayLine.displayLineVersion &&
                            editDisplayLine.displayLineVersion.devices &&
                            editDisplayLine.displayLineVersion.devices != null
                            ? JSON.parse(
                              editDisplayLine.displayLineVersion.devices
                            )
                            : []
                        )
                      )
                    }
                    type="link"
                  >
                    {"Edit"}
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={
              editDisplayLine &&
                editDisplayLine.displayLineVersion &&
                editDisplayLine.displayLineVersion.devices &&
                editDisplayLine.displayLineVersion.devices != null
                ? JSON.parse(editDisplayLine.displayLineVersion.devices)
                : []
            }
            renderItem={(device) => <List.Item>{device}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>Site List</h2>
            </div>
            <div className="card-body">
              <ul id="siteList">
                {editDisplayLine &&
                  editDisplayLine.displayLineVersion &&
                  editDisplayLine.displayLineVersion.siteList &&
                  editDisplayLine.displayLineVersion.siteList != null &&
                  editDisplayLine.displayLineVersion.siteListId &&
                  editDisplayLine.displayLineVersion.siteListId != null ? (
                  <Link
                    to={`/site-list/${editDisplayLine.displayLineVersion.siteListId}`}
                  >
                    <li>{editDisplayLine.displayLineVersion.siteList.name}</li>
                  </Link>
                ) : (
                  ""
                )}
              </ul>
              <div className="card-footer">
                {currentUserPermissionMap[currentUserRole][
                  "canUpdateLineSiteList"
                ] ? (
                  <Button
                    type="link"
                    onClick={() =>
                      dispatch(
                        openUpdateSiteListModal(
                          editDisplayLine &&
                          editDisplayLine.displayLineVersion &&
                          editDisplayLine.displayLineVersion.siteList &&
                          editDisplayLine.displayLineVersion.siteList,
                          editDisplayLine &&
                            editDisplayLine.campaign &&
                            editDisplayLine.campaign.siteLists &&
                            editDisplayLine.campaign.siteLists != null
                            ? editDisplayLine.campaign.siteLists
                            : []
                        )
                      )
                    }
                  >
                    Edit
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Geo - Country/US States"}
            footer={
              currentUserPermissionMap[currentUserRole][
                "canUpdateLineGeoTargeting"
              ] ? (
                <div>
                  <Button
                    onClick={() =>
                      dispatch(
                        openUpdateGeoNameModal(
                          editDisplayLine &&
                            editDisplayLine.displayLineVersion &&
                            editDisplayLine.displayLineVersion.geoNames &&
                            editDisplayLine.displayLineVersion.geoNames != null
                            ? JSON.parse(
                              editDisplayLine.displayLineVersion.geoNames
                            )
                            : []
                        )
                      )
                    }
                    type="link"
                  >
                    {"Edit"}
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={
              editDisplayLine &&
                editDisplayLine.displayLineVersion &&
                editDisplayLine.displayLineVersion.geoNames &&
                editDisplayLine.displayLineVersion.geoNames != null
                ? JSON.parse(editDisplayLine.displayLineVersion.geoNames)
                : []
            }
            renderItem={(geoName) => <List.Item>{geoName.name}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>DMA Targeting</h2>
            </div>
            <div className="card-body">
              <ul id="dmaTargeting">
                {editDisplayLine &&
                  editDisplayLine.displayLineVersion &&
                  editDisplayLine.displayLineVersion.dmaRegions &&
                  editDisplayLine.displayLineVersion.dmaRegions != null &&
                  JSON.parse(editDisplayLine.displayLineVersion.dmaRegions).map(
                    function (item) {
                      return <li>{item.name}</li>;
                    }
                  )}
              </ul>
              <div className="fusioncenter">
                <UsaDmaMap
                  selectedDmaRegions={
                    editDisplayLine &&
                    editDisplayLine.displayLineVersion &&
                    editDisplayLine.displayLineVersion.dmaRegions
                  }
                />
              </div>
              <div className="card-footer">
                {currentUserPermissionMap[currentUserRole][
                  "canUpdateLineDmaTargeting"
                ] ? (
                  <Button
                    type="link"
                    onClick={() =>
                      dispatch(
                        openUpdateDmaRegionModal(
                          editDisplayLine &&
                            editDisplayLine.displayLineVersion &&
                            editDisplayLine.displayLineVersion.dmaRegions &&
                            editDisplayLine.displayLineVersion.dmaRegions !=
                            null
                            ? JSON.parse(
                              editDisplayLine.displayLineVersion.dmaRegions
                            )
                            : []
                        )
                      )
                    }
                  >
                    Edit
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {currentUserPermissionMap[currentUserRole]["canAccessExelateSegments"] ? (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              header={"Exelate Segments"}
              footer={
                currentUserPermissionMap[currentUserRole][
                  "canUpdateLineExelateSegments"
                ] ? (
                  <div>
                    <Button
                      onClick={() =>
                        dispatch(
                          openUpdateExelateSegmentsModal(
                            editDisplayLine &&
                              editDisplayLine.displayLineVersion &&
                              editDisplayLine.displayLineVersion
                                .exelateSegments &&
                              editDisplayLine.displayLineVersion
                                .exelateSegments != null
                              ? JSON.parse(
                                editDisplayLine.displayLineVersion
                                  .exelateSegments
                              )
                              : []
                          )
                        )
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  </div>
                ) : (
                  ""
                )
              }
              bordered
              dataSource={
                editDisplayLine &&
                  editDisplayLine.displayLineVersion &&
                  editDisplayLine.displayLineVersion.exelateSegments &&
                  editDisplayLine.displayLineVersion.exelateSegments != null
                  ? JSON.parse(
                    editDisplayLine.displayLineVersion.exelateSegments
                  )
                  : []
              }
              renderItem={(exelateSegment) => (
                <List.Item>
                  {exelateSegment.exelateSegmentId +
                    " - " +
                    exelateSegment.name}
                </List.Item>
              )}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      {currentUserPermissionMap[currentUserRole]["canCreateLinePrivateBuy"] ? (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              header={"Private Buys"}
              footer={
                <div>
                  <Button
                    onClick={() => dispatch(openAddPrivateBuyModal())}
                    type="link"
                  >
                    {"New Private Buy"}
                  </Button>
                </div>
              }
              bordered
              dataSource={displayBuys && displayBuys}
              renderItem={(privateBuy) => (
                <Link
                  to={`/privateBuys/display/${privateBuy.displayPrivateBuyId}`}
                >
                  <List.Item>{privateBuy.name}</List.Item>
                </Link>
              )}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      <AddLineModal editLineInfo={editDisplayLine} />
      <EditDelivery editLineInfo={editDisplayLine} />
    </>
  );
};
function mapStateToProps(state) {
  return {
    displayBuys: state.privateBuy.displayBuys,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DisplayLineDetails
);
